<template>
  <div v-bind="{...$attrs, ...$props}" :style="style" ref="lavContainer"></div>
</template>

<script>
import lottie from 'lottie-web';

export default {
  name: "Lottie",
  props: {
    options: {
      type: Object,
      required: true
    },
    styles: {
      type: Object,
    },
    height: [Number, String],
    width: [Number, String],
  },

  data() {
    return {
      style: {
        overflow: 'hidden',
        background: 'rgba(255, 255, 255, 0.0)',
      }
    };
  },

  mounted() {
    this.anim = lottie.loadAnimation({
          container: this.$refs.lavContainer,
          renderer: 'svg',
          loop: this.options.loop !== false,
          autoplay: this.options.autoplay !== false,
          animationData: this.options.animationData,
          rendererSettings: this.options.rendererSettings
        }
    );
    this.$emit('animCreated', this.anim);
  },
};
</script>
