<template>
  <b-container class="under-construction">
    <div class="wrapper d-flex flex-column justify-content-center align-items-center">
      <h1>UNDER CONSTRUCTION</h1>

      <p class="py-4">
        Well! It's finally the time to give this site a facelift. <b>See you soon</b>!
      </p>

      <div class="coffee">
        <Lottie :options="coffee" />
      </div>

      <div class="socials">
        <a href="mailto:hi@jeffreybello.me" class="mr-3">
          <b-icon-envelope-fill class="envelope" />
        </a>

        <a href="https://www.linkedin.com/in/jeffreybello/" target="_blank">
          <b-icon-linkedin />
        </a>
      </div>
    </div>
  </b-container>
</template>

<script>
import Lottie from "@/components/Lottie";
import * as coffee from "@/assets/animations/coffee2.json";
export default {
  name: "UnderConstruction",
  components: { Lottie },
  data() {
    return {
      coffee: {
        animationData: coffee.default,
        loop: true,
        autoplay: true
      },
    }
  },
  created() {
    document.title = "COMING SOON!"
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/variables";
body {
  background-color: $white;
  color: black;
}

.under-construction {
  .wrapper {
    height: 100vh;

    h1 {
      font-weight: bold;
      text-align: center;
    }

    p {
      text-align: center;
      font-size: 18px;
    }

    .coffee {
      width: 300px;
      height: 300px;
      margin-top: -90px;
    }

    .socials {
      position: absolute;
      bottom: 0;
      height: 80px;

      display: flex;
      flex-direction: row;
      align-items: center;

      a {
        width: 40px;
        height: 40px;
        border: 1px solid $affair;
        border-radius: 50%;
        color: $affair;

        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        &:hover {
          background-color: $affair;
          color: $white;
          opacity: 1;
        }
      }
    }
  }
}
</style>
